<script setup>
const store = useClientState();
const props = defineProps({
    sections: {
        type: Array,
        default: () => [],
    },
});
const blueColors = ['#0047BB', '#011A43', '#6498ED', '#00296C'];
onMounted(() => {
    if (props?.sections?.length) {
        store.firstPanelIsBlue.value = blueColors.includes(props.sections[0].color);
    }
});
</script>
<template>
    <FlexiblePanels
        v-for="(flexibleData, index) in sections"
        :key="'flexible'+index"
        :blocks="flexibleData.blocks"
    />
</template>
